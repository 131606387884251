import { Box } from '@mui/material'
import style from './style'

function Endpoint({ endpointsList }) {
	const formattedString = endpointsList[0]
		.replace('endpoint\n', '')
		.trim()
		.split(' ')

	const method = formattedString[0]
	const endpoint = formattedString[1]

	return (
		<Box sx={style}>
			<Box className="method">{method}</Box>
			<Box className="url">{endpoint}</Box>
		</Box>
	)
}

export default Endpoint
