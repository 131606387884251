const styles = {
	maxWidth: {
		sm: '80vw',
		lg: '80vw'
	},
	marginLeft: '20vw',
	position: 'absolute',
	bgcolor: 'background.main',
	color: 'text.main'
}

export default styles
