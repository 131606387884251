const style = {
	'display': 'flex',
	'backgroundColor': '#0A373D',
	'padding': 2,
	'.method': {
		pointerEvents: 'none',
		color: 'cyan',
		userSelect: 'none',
		fontWeight: 'bold'
	},
	'.url': {
		ml: 2,
		color: 'lime'
	}
}

export default style
