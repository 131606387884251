const sideContainer = {
	backgroundColor: 'background.menu',
	fontWeight: 300,
	px: 2,
	minWidth: {
		sm: '20vw',
		lg: '20vw'
	},
	maxWidth: {
		sm: '20vw',
		lg: '20vw'
	},
	position: 'fixed',
	overflow: 'scroll',
	top: 0,
	left: 0,
	bottom: 0
}

export default sideContainer
