import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown' // https://www.npmjs.com/package/react-markdown
import rehypeHighlight from 'rehype-highlight'
import style from './style'

function Response({ responsesList }) {
	// Adds ``` in beginning and end of the string,
	// so the Markdown recognizes it as "code"
	const formattedCodeSample = `\`\`\`${responsesList[0]}\`\`\``

	return (
		<Box sx={style}>
			<ReactMarkdown
				children={formattedCodeSample}
				rehypePlugins={[[rehypeHighlight]]}
			/>
		</Box>
	)
}

export default Response
