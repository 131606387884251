import { useEffect, useState } from 'react'
import { fetchMdFile } from 'helpers/functions'
import CodeTabs from './code/CodeTabs'
import Endpoint from './code/Endpoint'
import Response from './code/Response'
import { Typography, Box } from '@mui/material'

function ExampleBlock({ example }) {
	const [exampleFile, setExampleFile] = useState(null)
	const [endpoints, setEndpoints] = useState('')
	const [requests, setRequests] = useState('')
	const [responses, setResponses] = useState('')

	if (exampleFile && !(endpoints || requests || responses)) {
		const splitPageString = exampleFile.split('```')
		const endpointsList = []
		const requestsList = []
		const responsesList = []

		splitPageString.map(item => {
			switch (true) {
				case item.startsWith('endpoint'):
					endpointsList.push(item)
					break

				case item.startsWith('curl'):
				case item.startsWith('python'):
					requestsList.push(item)
					break

				case item.startsWith('json'):
					responsesList.push(item)
					break

				default:
					break
			}
		})

		setEndpoints(endpointsList)
		setRequests(requestsList)
		setResponses(responsesList)
	}

	useEffect(() => {
		fetchMdFile(example.sample).then(formattedFile => setExampleFile(formattedFile))
	}, [exampleFile])

	return (
		<Box>
			{example.name && (
				<>
					<Typography
						component="h6"
						variant="h6"
						sx={{
							mb: 3
						}}>
						{example.name}
					</Typography>
				</>
			)}

			{!!endpoints.length && <Endpoint endpointsList={endpoints} />}
			{!!requests.length && <CodeTabs requestsList={requests} />}
			{!!responses.length && <Response responsesList={responses} />}
		</Box>
	)
}

export default ExampleBlock
