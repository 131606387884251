import { useContext } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import ReactMarkdown from 'react-markdown' // https://www.npmjs.com/package/react-markdown
import rehypeHighlight from 'rehype-highlight'
import bash from 'highlight.js/lib/languages/bash'
import style from './style'
import { SelectedCodeLanguageContext } from 'context'

function CodeTabs({ requestsList }) {
	const { selectedExampleLanguage, setSelectedExampleLanguage } = useContext(
		SelectedCodeLanguageContext
	)
	const tabsElements = []
	const tabPanelsElements = []

	const availableLanguages = requestsList.map(item =>
		item.startsWith('curl') ? 'curl' : 'python'
	)

	const isGlobalSelectedLanguagePresent =
		availableLanguages.indexOf(selectedExampleLanguage) >= 0

	// Fallback to the first available language, if the globally selected one
	// is not currently present
	const selectedTab = isGlobalSelectedLanguagePresent
		? selectedExampleLanguage
		: availableLanguages[0]

	requestsList.map((item, index) => {
		// TODO raw code needs to be provided somewhere here, so the COPY code will work
		const currentLanguage = item.startsWith('curl') ? 'curl' : 'python'

		tabsElements.push(
			<Tab key={index} label={currentLanguage} value={currentLanguage} />
		)

		// Adds ``` in beginning and end of the string,
		// so the Markdown recognizes it as "code"
		const formattedCodeSample = `\`\`\`${item}\`\`\``

		let isTabHidden

		if (isGlobalSelectedLanguagePresent) {
			isTabHidden = selectedExampleLanguage !== currentLanguage
		} else {
			isTabHidden = false
		}

		tabPanelsElements.push(
			<Box key={index} sx={style} hidden={isTabHidden}>
				<ReactMarkdown
					children={formattedCodeSample}
					rehypePlugins={[
						[
							rehypeHighlight,
							{
								languages: { curl: bash },
								ignoreMissing: true
							}
						]
					]}
				/>
			</Box>
		)
	})

	const handleChange = (event, newValue) => {
		setSelectedExampleLanguage(newValue)
	}

	return (
		<Box>
			<Tabs value={selectedTab} onChange={handleChange}>
				{tabsElements}
			</Tabs>
			{tabPanelsElements}
		</Box>
	)
}

export default CodeTabs
