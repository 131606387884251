const style = {
	'pre': {
		backgroundColor: '#0A373D',
		color: 'white'
	},
	'.hljs-string': {
		color: 'orange'
	},
	'.hljs-punctuation': {
		color: 'yellow'
	},
	'.hljs-attr': {
		color: 'cyan'
	},
	'.hljs-keyword': {
		color: 'red'
	},
	'.hljs-number': {
		color: 'lime'
	}
}

export default style
