import { Link } from '@mui/material'
import styles from './styles'

function Subtopic({ props }) {
	const { topic, anchorTag, pageRef, menuRef } = props

	return (
		<Link
			key={anchorTag}
			ref={menuRef}
			sx={styles}
			onClick={() =>
				pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
			href={anchorTag}>
			{topic}
		</Link>
	)
}

export default Subtopic
