const topicExamplesGridStyles = {
	'&:first-of-type:not(:only-child)': {
		lg: {
			paddingRight: 10
		}
	},
	'&:last-child:not(:only-child)': {
		lg: {
			paddingLeft: 10
		}
	},
	'pre': {
		padding: 2,
		overflowY: 'scroll',
		bgcolor: '#f6f8fa',
		maxHeight: '500px',
		fontSize: '0.8rem'
	},
	'.sticky-container': {
		'position': 'sticky',
		'top': 0,
		'> div': {
			'&:not(:first-of-type)': {
				borderTop: '1px solid darkgray',
				paddingTop: '1rem',
				marginTop: '3rem'
			}
		}
	}
}

export default topicExamplesGridStyles
