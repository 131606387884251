import { Typography, Container } from '@mui/material'
import { useState, useContext } from 'react'
import Topic from 'components/left-side/Topic'
import Subtopic from 'components/left-side/Subtopic'
import styles from './styles'
import { ViewportTopPageContext } from 'context'

const getSectionElement = ({ section }) => (
	<Typography key={section} sx={styles}>
		{section}
	</Typography>
)

function SideMenu({ sections }) {
	const [elements, setElements] = useState([])
	const { vpTopPage, setVpTopPage } = useContext(ViewportTopPageContext)

	// TODO fix this check
	if (sections.length && !elements.length) {
		const newSideMenuContent = []

		for (const section of sections) {
			newSideMenuContent.push(getSectionElement(section))

			section.items.forEach(topic => {
				newSideMenuContent.push(<Topic key={topic.anchorTag} props={topic} />)

				topic.subtopics.forEach(subtopic => {
					newSideMenuContent.push(<Subtopic key={subtopic.anchorTag} props={subtopic} />)
				})
			})
		}

		setElements(newSideMenuContent)
	}

	return <Container disableGutters>{elements}</Container>
}

export default SideMenu
