const style = {
	'pre': {
		backgroundColor: '#0A373D',
		color: 'white'
	},
	'.hljs-string': {
		color: 'orange'
	},
	'.hljs-keyword': {
		color: 'lime'
	},
	'.hljs-comment': {
		color: 'blueviolet'
	},
	'.hljs-class': {
		color: 'chocolate'
	},
	'.hljs-function': {
		color: 'violet'
	},
	'.hljs-literal': {
		color: 'tomato'
	},
	'.hljs-params': {
		color: 'sandybrown'
	},
	'.hljs-meta': {
		color: 'darkgray'
	},
	'.hljs-built_in': {
		color: 'cyan'
	}
}

export default style
