const styles = {
	'mb': 1,
	'color': 'white',
	'fontWeight': 600,
	'display': 'block',
	'textDecorationColor': 'transparent',
	'textUnderlineOffset': '2px',
	'transition': '0.2s',
	'&:hover': {
		textDecorationColor: 'text.main'
	}
}

export default styles
