import { useEffect, useState, useContext } from 'react'
import { Grid } from '@mui/material'
import TopicInfo from '../TopicInfo'
import topicInfoGridStyles from './styles/topic-info-grid-styles'
import topicExamplesGridStyles from './styles/topic-examples-grid-styles'
import singlePageGridContainer from 'styles/single-page-grid-container'
import { fetchMdFile } from 'helpers/functions'
import ExampleBlock from '../ExampleBlock'
//import { useOnScreen } from 'custom/functions'
//import { ViewportTopPageContext } from 'context'

function TopicContainer({ pageRef, menuRef, page, anchorTag, examples }) {
	const [mainPage, setMainPage] = useState('')
	const [topicExamples, setTopicExamples] = useState('')
	const [isPageReady, setIsPageReady] = useState(false)
	//const isVisible = useOnScreen(pageRef)
	//const { vpTopPage, setVpTopPage } = useContext(ViewportTopPageContext)

	/*
	useEffect(() => {
		if (isVisible) setVpTopPage(menuRef)
	}, [isVisible])
	*/

	useEffect(() => {
		fetchMdFile(page)
			.then(formattedFile => setMainPage(formattedFile))
			.then(() => setIsPageReady(true))

		if (examples) setTopicExamples(examples)
	}, [])

	return (
		<Grid container ref={pageRef} sx={singlePageGridContainer}>
			<Grid item xs={12} lg={6} sx={topicInfoGridStyles}>
				<TopicInfo page={mainPage} />
			</Grid>

			{topicExamples && (
				<Grid item xs={12} lg={6} sx={topicExamplesGridStyles}>
					<div className="sticky-container">
						{topicExamples.map(example => (
							<ExampleBlock key={example.sample} example={example} />
						))}
					</div>
				</Grid>
			)}
		</Grid>
	)
}

export default TopicContainer
