const imageBox = {
	width: 1,
	display: 'flex',
	justifyContent: 'space-between',
	py: 2,
	img: {
		maxWidth: 100
	}
}

export default imageBox
