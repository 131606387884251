import { Fragment } from 'react'
import Markdown from 'markdown-to-jsx' // https://www.npmjs.com/package/markdown-to-jsx

const TableContainer = ({ children }) => (
	<div className="table-container">
		<table>{children}</table>
	</div>
)

function TopicInfo({ page }) {
	return (
		<Markdown
			children={page}
			options={{
				wrapper: Fragment,
				overrides: {
					table: TableContainer
				}
			}}
		/>
	)
}

export default TopicInfo
