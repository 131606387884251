const singlePageGridContainer = {
	'paddingX': 5,
	'paddingY': 4,
	'borderBottom': 1,
	'borderColor': '#ddd',
	'h2, h3, h4': {
		marginTop: 'unset'
	}
}

export default singlePageGridContainer
