import React, { useState } from 'react'
import { Container } from '@mui/material'
import styles from './styles'
import TopicContainer from '../TopicContainer'

function PagesContainer({ pages }) {
	const [elements, setElements] = useState([])

	if (pages.length && !elements.length) {
		const newElements = []

		const getTopicContainer = ({ page, anchorTag, pageRef, menuRef, examples }) => (
			<TopicContainer
				key={anchorTag}
				anchorTag={anchorTag}
				pageRef={pageRef}
				menuRef={menuRef}
				page={page}
				examples={examples}
			/>
		)

		pages.forEach(({ items }) => {
			items.forEach(topic => {
				newElements.push(getTopicContainer(topic))

				topic.subtopics.forEach(subtopic => {
					newElements.push(getTopicContainer(subtopic))
				})
			})
		})

		setElements(newElements)
	}

	return (
		<Container disableGutters sx={styles}>
			{elements}
		</Container>
	)
}

export default PagesContainer
