// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
	font-size: 15px;
}

body {
	margin: 0;
}

*:not(code) {
	line-height: 2em;
	font-family: 'Montserrat', sans-serif !important;
	transition: background-color 0.2s linear;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,gBAAgB;CAChB,gDAAgD;CAChD,wCAAwC;AACzC","sourcesContent":["html {\n\tfont-size: 15px;\n}\n\nbody {\n\tmargin: 0;\n}\n\n*:not(code) {\n\tline-height: 2em;\n\tfont-family: 'Montserrat', sans-serif !important;\n\ttransition: background-color 0.2s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
