import React, { useEffect, useState } from 'react'

export function hasOwnProperty(object, key) {
	Object.prototype.hasOwnProperty.call(object, key)
}

export function getAnchorTag(section, topic) {
	return `#${section}-${topic}`.replaceAll(' ', '_')
}

// Format list to have anchor tags
export function addAnchorTagsToContent(sections) {
	const content = []

	sections.forEach(({ section, items }) => {
		const newSection = {
			section,
			items: []
		}

		items.forEach(({ topic, page, examples, subtopics }) => {
			const newTopic = {
				anchorTag: getAnchorTag(section, topic),
				pageRef: React.createRef(),
				menuRef: React.createRef(),
				topic,
				page,
				subtopics: []
			}

			if (examples) newTopic['examples'] = examples

			if (subtopics) {
				subtopics.forEach(subtopic => {
					const newSubtopic = {
						anchorTag: getAnchorTag(section, `${topic}-${subtopic.topic}`),
						pageRef: React.createRef(),
						menuRef: React.createRef(),
						topic: subtopic.topic,
						page: subtopic.page
					}

					if (subtopic.examples) newSubtopic['examples'] = subtopic.examples

					newTopic.subtopics.push(newSubtopic)
				})
			}

			newSection.items.push(newTopic)
		})

		content.push(newSection)
	})

	return content
}

// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
// This hook allows you to listen for elements being shown on the screen as you scroll.
// The event is fired once they are starting to show up from the bottom of the viewport
export function useOnScreen(ref) {
	const [isIntersecting, setIntersecting] = useState(false)

	// This specific setting for the root margin tells the browser that we need to have the event
	// once the element is in the top of the viewport, rather than at the bottom.
	// Hence the negative 100% bottom margin
	const options = {
		rootMargin: '0% 0% -100% 0%'
	}

	const observer = new IntersectionObserver(([entry]) => {
		return setIntersecting(entry.isIntersecting)
	}, options)

	useEffect(() => {
		observer.observe(ref.current)

		// Remove the observer as soon as the component is unmounted
		return () => observer.disconnect()
	}, [])

	return isIntersecting
}

export async function fetchMdFile(pageToFetch) {
	const formattedPage = await fetch(`content/${pageToFetch}.md`, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	}).then(file => file.text())

	return formattedPage
}
