import React from 'react'

export const ViewportTopPageContext = React.createContext({
	vpTopPage: '',
	setVpTopPage: {}
})

export const SelectedCodeLanguageContext = React.createContext({
	codeLanguage: 'python',
	setCodeLanguage: {}
})
