const topicInfoGridStyles = {
	'color': 'text.main',
	'overflow': 'scroll',
	'&:first-of-type:not(:only-child)': {
		lg: {
			paddingRight: 10
		}
	},
	'&:last-child:not(:only-child)': {
		lg: {
			paddingLeft: 10
		}
	},
	'p > code': {
		px: 1,
		py: 0.5,
		bgcolor: 'text.code'
	},
	'pre': {
		padding: 2,
		overflowY: 'scroll',
		bgcolor: 'text.code',
		maxHeight: '500px',
		fontSize: '0.8rem'
	},
	'.table-container': {
		overflow: 'scroll',
		table: {
			width: 1,
			border: 1,
			borderBottom: 0,
			borderColor: '#ddd',
			borderSpacing: 0,
			textAlign: 'left'
		}
	},
	'th': {
		backgroundColor: '#0000000d'
	},
	'th, td': {
		'&:not(:last-child)': {
			borderRight: 1
		},
		'borderBottom': 1,
		'borderColor': '#ddd',
		'borderRightColor': '#ddd !important',
		'padding': 1,
		'px': 2,
		'fontSize': '0.8rem'
	},
	'details > summary': {
		cursor: 'pointer',
		userSelect: 'none',
		'> b': {
			paddingLeft: '6px'
		}
	}
}

export default topicInfoGridStyles
