const appTheme = mode => {
	return {
		primary: {
			main: '#54BDCB'
		},
		background: {
			main: mode === 'dark' ? '#1a1f36' : 'white',
			menu: mode === 'dark' ? '#052428' : '#0A373D'
		},
		text: {
			main: mode === 'dark' ? 'white' : 'black',
			code: mode === 'dark' ? '#0A373D' : '#0000000d'
		}
	}
}

export default appTheme
