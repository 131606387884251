import React, { useEffect, useState } from 'react'
import { Container, Typography, Box, Stack, ListItem, Select, ListItemButton, ListItemText, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Brightness3Icon from '@mui/icons-material/Brightness3'
import LightModeIcon from '@mui/icons-material/LightMode'
import SideMenu from 'components/left-side/SideMenu'
import risikaLogo from 'icons/logo-risika.svg'
import appContainer from 'styles/app-container'
import sideContainer from 'styles/side-container'
import imageBox from 'styles/image-box'
import PagesContainer from 'components/right-side/PagesContainer'
import { addAnchorTagsToContent } from 'helpers/functions'
import { SelectedCodeLanguageContext } from 'context'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import appTheme from 'custom/app-theme'
import { List } from '@mui/icons-material'
import axios from 'axios'
import { slotShouldForwardProp } from '@mui/material/styles/styled'

function App() {
	const [content, setContent] = useState([])
	//const [vpTopPage, setVpTopPage] = useState('')
	const [selectedExampleLanguage, setSelectedExampleLanguage] = useState('python')
	const [appMode, setAppMode] = useState('light')
	const [versions, setVersions] = React.useState([])
	const url = window.location.href
	const [selectedVersion, setSelectedVersion] = React.useState('')
	// Get the url the user is on
	const contextValue = { selectedExampleLanguage, setSelectedExampleLanguage }
	React.useEffect(() => {
			axios.get('https://thornwebapi.risika.com/api/version-list')
					.then((response) => {
							const versionList = response.data?.length > 0 ? response.data : []
							setSelectedVersion(versionList.find((version) => url.includes(version)) || 'latest')
							setVersions(versionList)
					})
	}, [])
	const theme = createTheme({
		palette: {
			mode: appMode,
			...appTheme(appMode)
		}
	})
	const handleChange = (e) => {

		// get everything after the # in the url
		const url = window.location.href
		const hash = url.split('#')[1]
		window.location.href = `https://docs.risika.com/${e.target.value}/#${hash}`
	}
	const changeAppThemeMode = () => {
		setAppMode(appMode === 'light' ? 'dark' : 'light')
	}

	const fetchContentJson = () => {
		fetch('content/content.json', {
			headers: {
				'Content-Type': 'application/json',
				'Accept': '*/*'
			}
		})
			.then(jsonFile => jsonFile.json())
			.then(sections => addAnchorTagsToContent(sections))
			.then(formattedContent => setContent(formattedContent))
	}

	// Uncomment when Side Menu scroll should work
	/*
	useEffect(() => {
		if (vpTopPage) {
			vpTopPage.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}, [vpTopPage])
	*/

	useEffect(() => {
		fetchContentJson()
	}, [])

	return (
			<SelectedCodeLanguageContext.Provider value={contextValue}>
				<ThemeProvider theme={theme}>
					<Container disableGutters width="100vw" sx={appContainer}>
						<Container disableGutters sx={sideContainer}>
							<Box sx={imageBox}>
								<img alt="company logo" src={risikaLogo} />

								<IconButton onClick={changeAppThemeMode} color="primary" component="span">
									{appMode === 'dark' ? <LightModeIcon /> : <Brightness3Icon />}
								</IconButton>

								{/* Beta tag start */}
{/*									<Box
									sx={{
										background: 'orange',
										transform: 'rotateY(0deg) rotate(315deg)',
										position: 'absolute',
										left: '-208px',
										top: '12px',
										width: '424px',
										textAlign: 'center',
										overflow: 'hidden',
										paddingLeft: '20px'
									}}>
									<Typography
										component="h6"
										variant="h6"
										sx={{
											color: 'white',
											cursor: 'default',
											fontSize: '1rem'
										}}>
										BETA
									</Typography>
								</Box>*/}
								{/* Beta tag end */}
							</Box>
							<Stack justifyContent='space-between' direction='row'>

								<Typography
									component="h3"
									variant="h6"
									sx={{ color: 'white', mb: 4, cursor: 'default' }}>
									API documentation
								</Typography>
								<Select
									// fullWidth
									sx={{ backgroundColor: 'white', height: '2rem' }}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={selectedVersion}
									label="API version"
									onChange={handleChange}
								>
									{
										versions?.map((version) => (
											<MenuItem key={version} value={version}>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemText primary={version} />
													</ListItemButton>
												</ListItem>
											</MenuItem>
										))
									}
								</Select>
							</Stack>
							<SideMenu sections={content} />
						</Container>

						<PagesContainer pages={content} />
					</Container>
				</ThemeProvider>
			</SelectedCodeLanguageContext.Provider>
	)
}

export default App
